exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cargo-tsx": () => import("./../../../src/pages/cargo.tsx" /* webpackChunkName: "component---src-pages-cargo-tsx" */),
  "component---src-pages-cobalt-tsx": () => import("./../../../src/pages/cobalt.tsx" /* webpackChunkName: "component---src-pages-cobalt-tsx" */),
  "component---src-pages-deui-tsx": () => import("./../../../src/pages/deui.tsx" /* webpackChunkName: "component---src-pages-deui-tsx" */),
  "component---src-pages-golem-tsx": () => import("./../../../src/pages/golem.tsx" /* webpackChunkName: "component---src-pages-golem-tsx" */),
  "component---src-pages-hive-tsx": () => import("./../../../src/pages/hive.tsx" /* webpackChunkName: "component---src-pages-hive-tsx" */),
  "component---src-pages-huxtaburger-tsx": () => import("./../../../src/pages/huxtaburger.tsx" /* webpackChunkName: "component---src-pages-huxtaburger-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-streamr-tsx": () => import("./../../../src/pages/streamr.tsx" /* webpackChunkName: "component---src-pages-streamr-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-vizor-tsx": () => import("./../../../src/pages/vizor.tsx" /* webpackChunkName: "component---src-pages-vizor-tsx" */)
}

